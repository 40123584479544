<template>
    <div class="about">
        <div id="nav">
            <router-link to="/home">Home</router-link>
            |
            <router-link to="/about">About</router-link>
            |
            <router-link to="/">Logout</router-link>
        </div>
        <h1>This is an about page</h1>
    </div>
</template>
<script>
    export default {
        name: 'about',
    }
</script>
